<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="line" height="400" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import performaceEnergChartData from "./performaceEnergChartData";
import moment from "moment-timezone";
import createTrend from "trendline";
//const createTrend = require('trendline');

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      performaceEnergChartData,
      series: [],
      chartOptions: {
        colors: ["#2f79bb", "#4faef2"],
        markers: {
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: "#2f79bb",
          colors: "#2f79bb",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true,
            type: "xy",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        fill: {
          type: "solid",
        },
        markers: {
          size: [6, 0],
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: "#2f79bb",
          colors: "#2f79bb",
        },
        tooltip: {
          shared: false,
          intersect: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(1) + " W";
            },
          },
          tickAmount: 1,
        },
        noData: {
          text: "No Data",
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `EVAPORATOR_DEFROST_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}
  },
  methods:
  {
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH",'loading_defrost_equipment_1');

      this.series = []
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      let deviceId = this.$route.params.id;
      let metricsEndpoint = ``
      if (this.timeInterval > 2) {
        metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=defrost_equipment_1&window=15`;
      }
      else {
        metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=defrost_equipment_1&mean=false`;
      }
      this.$http.get(metricsEndpoint).then((res) => {
        let options = { ...this.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;

        this.chartOptions = options;

        let processed = [];
        for (let index = 0; index < timestampCategories.length; index += 5) {
          processed.push([
            timestampCategories[index],
            Number(res.data.data[index].toFixed(2)),
          ]);
        }
        this.series.push({
          name: this.$t("Probe Temperature"),
          type: "scatter",
          data: processed,
        });

        let trend2 = [];
        trend2 = res.data.data.map((el, index) => {
          return { x: index, y: el };
        });

        const trend = createTrend(trend2, "x", "y");
        let processedTrendLine = [];
        processedTrendLine.push([processed[0][0], trend.calcY(0)]);
        processedTrendLine.push([
          processed[processed.length - 1][0],
          trend.calcY(processed.length - 1),
        ]);
        this.series.push({
          name: this.$t("Trend"),
          type: "line",
          data: processedTrendLine,
        });
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH",'loading_defrost_equipment_1');
      });
    }
  },
  created() {
    this.getData();
  },
};
</script>