<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <device-info-generic />
        <device-info-generic-banner />
      </b-col>
    </b-row>

    <b-row class="match-height ">
      <b-col lg="12" sm="12">
        <device-info-adequacao />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <device-info-envolvente />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <device-info-performace />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <device-info-detalhes />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import DeviceInfoGeneric from "./DeviceInfoGeneric.vue";
import DeviceInfoGenericBanner from "./DeviceInfoGenericBanner.vue"
import DeviceInfoAdequacao from "./Adequacao/DeviceInfoAdequacao.vue";
import DeviceInfoEnvolvente from "./Envolvente/DeviceInfoEnvolvente.vue";
import DeviceInfoPerformace from "./Performace/DeviceInfoPerformace.vue";
import DeviceInfoDetalhes from "./Detalhes/DeviceInfoDetalhes.vue";

export default {
  components: {
    BRow,
    BCol,
    DeviceInfoGeneric,
    DeviceInfoAdequacao,
    DeviceInfoEnvolvente,
    DeviceInfoPerformace,
    DeviceInfoDetalhes,
    DeviceInfoGenericBanner,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.$store.dispatch("devices/getSingleDevice", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getGenericDevicelMetric", {
      context: this,
      id: this.$route.params.id,
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-device.scss";
</style>
