export default {
  lineChartSimple: {
    series: [
      {
        name: "Potency",
        data: [0, 10, 15, 10, 12, 5, 0],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 1,
        strokeOpacity: 1,
        strokeColors: '#2f79bb',
        colors: '#2f79bb',
      },
      colors: ['#2f79bb'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }kWh</span></div>`
        },
      },
      xaxis: {
        // categories: [],
      },
      yaxis: {
        // opposite: isRtl,
        labels: {
          formatter: function (val, index) {
            return val.toFixed(0) + " kWh";
          }
        },
        tickAmount:1,
      },
    },
  },
}
