<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import adequacaoChartData from "./adequacaoChartData";
import moment from "moment-timezone";
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      adequacaoChartData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        // markers: {
        //   strokeWidth: 1,
        //   strokeOpacity: 1,
        //   strokeColors: '#2f79bb',
        //   colors: '#2f79bb',
        // },
        colors: ["#405082", "#2f79bb", "#ED7C30", "#F4B389"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            position: "top",
            categories: ["1º", "2º", "3º", "4º", "5º"],
            offsetY: 10,
            labels: {
              style: {
                colors: "#5b5b5b",
                fontSize: "0.86rem",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "600",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            position: "top",
            offsetY: -5,
            itemMargin: {
              horizontal: 5,
              vertical: 10,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#405082", "#2f79bb", "#ED7C30", "#F4B389"],
          plotOptions: {
            bar: {
              columnWidth: "20px;",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              show: false,
              style: {
                colors: "#5b5b5b",
                fontSize: "0.86rem",
              },
            },
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + "ºC";
            },
          },
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    currentMetricBlastChiller() {
      return this.$store.state.devices.currentMetricBlastChiller;
    },
    isInternalTempDataValid() {
      const invalidValues = [32767, -32764];

      return !(
        invalidValues.includes(this.currentMetricBlastChiller.internal_temp) ||
        invalidValues.includes(this.currentMetricBlastChiller.internal_temp_set)
      );
    },
    isProbeTempDataValid() {
      const invalidValues = [32767, -32764];

      return !(
        invalidValues.includes(this.currentMetricBlastChiller.probe_temp) ||
        invalidValues.includes(this.currentMetricBlastChiller.probe_temp_set)
      );
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `OPERATION_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    'currentMetricBlastChiller.cycle_type_status'(newProp, oldProp) {
      this.getData();
    },
    fileName(){}
  },
  methods: {
    async getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH_BLAST_CHILL",'loading_adeq_top');
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      const deviceId = this.$route.params.id;
      const metricsEndpointTemp = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=internal_temperature`;
      const metricsEndpointTempSet = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=internal_temperature_set`;
      const metricsEndpointNeedleProbeTemp = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=probe_temp_blast_chill`;
      const metricsEndpointNeedleProbeTempSet = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=probe_temp_blast_chill_set`;

      const windowParam = this.timeInterval > 2 ? "&window=15" : "";

      let series = [];
      let timestampCategories = [];
      const options = { ...this.chartOptions };

      options.xaxis.labels.format = labelFormat;

      try {

        if ([0, 1].includes(this.currentMetricBlastChiller.cycle_type_status)) {
          const internalTemp = this.currentMetricBlastChiller.internal_temp;
          const internalTempSet = this.currentMetricBlastChiller.internal_temp_set;
          const probeTemp = this.currentMetricBlastChiller.probe_temp;
          const probeTempSet = this.currentMetricBlastChiller.probe_temp_set;

          if (this.isInternalTempDataValid) {
            const internalTempRes = await this.$http.get(`${metricsEndpointTemp}${windowParam}`);
            const internalTempSetRes = await this.$http.get(`${metricsEndpointTempSet}${windowParam}`);

            const internalTempDataset = {
              name: this.$t("Internal Temperature"),
              data: internalTempRes.data.data.map((el) => {
                return Number(el.toFixed(2));
              }),
            };

            const internalTempSetDataset = {
              name: this.$t("Internal Temperature Set"),
              data: internalTempSetRes.data.data.map((el) => {
                return Number(el.toFixed(2));
              }),
            };

            series = [
              ...series,
              internalTempDataset,
              internalTempSetDataset,
            ];

            timestampCategories = internalTempRes.data.categories.map((cat) => {
              return moment(new Date(cat)).tz(this.deviceTZ).format();
            });
          }


          if (this.currentMetricBlastChiller.cycle_type_status === 0 && this.isProbeTempDataValid) {
            const needleProbeTempRes = await this.$http.get(`${metricsEndpointNeedleProbeTemp}${windowParam}`);
            const needleProbeTempSetRes = await this.$http.get(`${metricsEndpointNeedleProbeTempSet}${windowParam}`);

            const needleProbleTempDataset = {
              name: this.$t("Needle Probe Temp"),
              data: needleProbeTempRes.data.data.map((el) => {
                return Number(el.toFixed(2));
              }),
            };

            const needleProbleTempSetDataset = {
              name: this.$t("Needle Probe Temp Set"),
              data: needleProbeTempSetRes.data.data.map((el) => {
                return Number(el.toFixed(2));
              }),
            };

            series = [
              ...series,
              needleProbleTempDataset,
              needleProbleTempSetDataset,
            ];
          }
          else {
            options.noData.text = "No Data";
            options.xaxis.categories = [];
          }

          options.xaxis.categories = timestampCategories;

        } else {
          options.noData.text = "No Data";
          options.xaxis.categories = [];
        }
      } catch (error) {
        console.error(error);

        options.noData.text = "No Data";
        options.xaxis.categories = [];
        series = [];
      } finally {
        this.series = series;
        this.chartOptions = options;
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH_BLAST_CHILL",'loading_adeq_top');
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>