<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="line" height="300" :options="chartOptions" :series="series" @click="clickHandler" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment-timezone";
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        // markers: {
        //   strokeWidth: 1,
        //   strokeOpacity: 1,
        //   strokeColors: '#2f79bb',
        //   colors: '#2f79bb',
        // },
        colors: ["#2f79bb", "transparent"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            text: "60%",
            formatter: function (val, index) {
              return val.toFixed(0) + "%";
            },
          },
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `EQUIPMENT_STRESS_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}

  },
  methods: {
    clickHandler(event, chartContext, config) {
      let dataToSend = {};
      for (let s of config.config.series) {
        dataToSend[s.name] = s.data[config.dataPointIndex];
      }
      this.$emit("updateBarsEffort", dataToSend);
    },
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH",'loading_perform_effor');
      const labelFormat = this.timeInterval > 2 ? 'dd/MM' : 'HH:mm';
      let deviceId = this.$route.params.id;
      this.series = []
      let promises = [];
      let metricsEndpoint1 = ''
      let metricsEndpoint2 = ''
      if (this.timeInterval > 2) {
        metricsEndpoint1 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=compressor_time&window=15`;
        metricsEndpoint2 = `${process.env.VUE_APP_API_URL
          }api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval * 2
          }&metric_field=compressor_time&window=15`;
      }
      else {
        metricsEndpoint1 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=compressor_time&mean=false`;
        metricsEndpoint2 = `${process.env.VUE_APP_API_URL
          }api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval * 2
          }&metric_field=compressor_time&mean=false`;
      }

      promises.push(this.$http.get(metricsEndpoint1));
      promises.push(this.$http.get(metricsEndpoint2));

      Promise.all(promises).then((res) => {
        let res1, res2;
        if (res[0].data.data.length < res[1].data.data.length) {
          res1 = res[0].data;
          res2 = res[1].data;
        } else {
          res1 = res[1].data;
          res2 = res[0].data;
        }
        let options = { ...this.chartOptions };
        let timestampCategories = res1.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions = options;

        let total_time = this.timeInterval * 60 * 60;
        let processed_values = [];

        for (let index = 0; index < res1.data.length; index++) {
          processed_values.push(
            ((res1.data[index] - res2.data[index]) / total_time) * 100
          );
        }

        this.series.push({
          name: "Equipment Stress",
          data: processed_values,
        });
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH",'loading_perform_effor');
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

