<template>
  <div id="02">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="envolvente-device">
          <b-card-body>
            <div class="envolvente-top">
              <span class="envolvente-icon">
                <feather-icon icon="CreditCardIcon" />
              </span>
              <span class="text-envolvente"> {{ $t("Ambient") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/cooker.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Ambient Temperature and Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingEvolvTemp" class="text-center" style="height: 350px; align-content: center;">
              <b-spinner variant="primary" key="primary"></b-spinner>
            </div>
              <b-col :style="isLoadingEvolvTemp ? 'display:none' : ''"  lg="6" >
                <device-info-envolvente-temp-amb @updateBarsHumidityTemp="updateSliders"/>
                <div class="mb-1">
                  <span class="graph-dot-blue"></span>
                  <span class="graph-legend">{{
                $t("Ambient Temperature")
              }}</span>
                </div>
                <vue-slider v-model="value" :direction="direction" class="mb-2 p-0 range-temp" />
              </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="12" lg="12">
              <device-info-envolvente-portas />
            </b-col>
            <!-- <b-col md="12" lg="6">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/open-door.png" alt="" />
                </span>
                <span class="text-int"> Impacto da abertura de portas </span>
              </div>
              <span>
                <feather-icon class="download-icon" icon="CloudOffIcon" />
              </span>
              <b-card no-body>
                <b-card-body>
                  <vue-apex-charts
                    type="bar"
                    height="230"
                    :options="revenueReport.chartOptions"
                    :series="revenueReport.series"
                  />
                </b-card-body>
              </b-card>
            </b-col> -->
          </b-row>
          <hr v-if="hideIfHasDefrostSensorAndIsClient()" />
          <b-row v-if="hideIfHasDefrostSensorAndIsClient()">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/condensador.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Condenser Undercooling") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingSubCond" class="text-center" style="height: 350px; align-content: center;">
              <b-spinner variant="primary" key="primary"></b-spinner>
            </div>
              <b-col :style="isLoadingSubCond ? 'display:none' : ''" lg="6">
                <device-info-envolvente-subarrefecimento-condensador />
              </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import store from "@/store/index";
import DeviceInfoEnvolventeTempAmb from "../Envolvente/DeviceInfoEnvolventeTempAmb.vue";
import DeviceInfoEnvolventeSobreaquec from "../Envolvente/DeviceInfoEnvolventeSobreaquec.vue";
import DeviceInfoEnvolventeTop from "../Envolvente/DeviceInfoEnvolventeTop.vue";
import VueGauge from "vue-gauge";
import VueApexCharts from "vue-apexcharts";
import DeviceInfoEnvolventeEficaciaCondensador from "@/views/device/Envolvente/DeviceInfoEnvolventeEficaciaCondensador.vue";
import DeviceInfoEnvolventeSubarrefecimentoCondensador from "@/views/device/Envolvente/DeviceInfoEnvolventeSubarrefecimentoCondensador.vue";
import DeviceInfoEnvolventePortas from "./DeviceInfoEnvolventePortas.vue";

export default {
  components: {
    VueSlider,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeTempAmb,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoEnvolventeTop,
    VueGauge,
    VueApexCharts,
    DeviceInfoEnvolventeEficaciaCondensador,
    DeviceInfoEnvolventeSubarrefecimentoCondensador,
    DeviceInfoEnvolventePortas,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
      value: 10,
      value2: 10,
      temp_status: "OK!",
      temp_status_condenser: "OK!",
      revenueReport: {
        series: [
          {
            name: "Numero de abertura de portas",
            data: [5, 10, 20, 5, 10],
          },
          {
            name: "% compressor on",
            data: [50, 60, 10, 70, 30],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            position: "top",
            categories: ["1º", "2º", "3º", "4º", "5º"],
            offsetY: 10,
            labels: {
              style: {
                colors: "#5b5b5b",
                fontSize: "0.86rem",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "600",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: true,
            position: "top",
            offsetY: -5,
            itemMargin: {
              horizontal: 5,
              vertical: 10,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#405082", "#2f79bb"],
          plotOptions: {
            bar: {
              columnWidth: "20px;",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              show: false,
              style: {
                colors: "#5b5b5b",
                fontSize: "0.86rem",
              },
            },
          },
        },
      },
    };
  },
  computed: {
    isLoadingEvolvTemp() {
      return this.$store.state.devices.graph_loadings.loading_envol_temp
    },
    isLoadingSubCond() {
      return this.$store.state.devices.graph_loadings.loading_sub_cond
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
    device_status() {
      this.currentAmbTempStatus;
      const level = { "OK!": 1, "Oops!": 2, "NOK!": 3 };
      const temp_level = level[this.temp_status];

      return this.temp_status;
    },
    device_status_condenser() {
      this.currentCondenserTemp;
      const level = { "OK!": 1, "Oops!": 2, "NOK!": 3 };
      const temp_level = level[this.temp_status_condenser];

      return this.temp_status;
    },
    currentAmbTempStatus() {
      let data = this.$store.state.devices.currentAmbTempStatus;
      this.verifyTempStatus(data);
    },
    currentCondenserTemp() {
      let data = this.$store.state.devices.currentCondenserTemp;
      this.verifyCondenserStatus(data);
    },
    currentDevicerRoles() {
      return this.$store.state.devices.currentDevicerRoles
    },
    hasDefrostSensor() {
      return this.$store.state.devices.currentDevice.has_defrosting_sensor;
    },
    userData() {
      return this.$store.state.users.currentUser
    },
  },
  methods: {
    updateSliders(data) {
      let dadosClasseClimatica = {
        3: (temp) => {
          if (temp < 23) {
            return 10;
          }
          if (temp >= 23 && temp <= 25) {
            return 50;
          }
          if (temp > 25) {
            return 90;
          }
        },
        4: (temp) => {
          if (temp < 28) {
            return 10;
          }
          if (temp >= 28 && temp <= 30) {
            return 50;
          }
          if (temp > 30) {
            return 90;
          }
        },
        5: (temp) => {
          if (temp < 38) {
            return 10;
          }
          if (temp >= 38 && temp <= 40) {
            return 50;
          }
          if (temp > 40) {
            return 90;
          }
        },
      };

      let classeClimatica =
        this.$store.state.devices.currentDevice.model_climate_class;

      this.value = dadosClasseClimatica[classeClimatica](
        data["Ambient Temperature"]
      );
    },
    verifyTempStatus(data) {
      let dadosClasseClimatica = {
        3: (temp) => {
          if (temp < 23) {
            this.temp_status = "OK!";
          }
          if (temp >= 23 && temp <= 25) {
            this.temp_status = "Oops!";
          }
          if (temp > 25) {
            this.temp_status = "NOK!";
          }
        },
        4: (temp) => {
          if (temp < 28) {
            this.temp_status = "OK!";
          }
          if (temp >= 28 && temp <= 30) {
            this.temp_status = "Oops!";
          }
          if (temp > 30) {
            this.temp_status = "NOK!";
          }
        },
        5: (temp) => {
          if (temp < 38) {
            this.temp_status = "OK!";
          }
          if (temp >= 38 && temp <= 40) {
            this.temp_status = "Oops!";
          }
          if (temp > 40) {
            this.temp_status = "NOK!";
          }
        },
      };

      let classeClimatica =
        this.$store.state.devices.currentDevice.model_climate_class;

      dadosClasseClimatica[classeClimatica](data);
    },
    verifyCondenserStatus(data) {
      let dadosClasseClimatica = {
        3: (temp) => {
          if (temp < 23) {
            this.temp_status = "OK!";
          }
          if (temp >= 23 && temp <= 25) {
            this.temp_status = "Oops!";
          }
          if (temp > 25) {
            this.temp_status = "NOK!";
          }
        },
        4: (temp) => {
          if (temp < 28) {
            this.temp_status = "OK!";
          }
          if (temp >= 28 && temp <= 30) {
            this.temp_status = "Oops!";
          }
          if (temp > 30) {
            this.temp_status = "NOK!";
          }
        },
        5: (temp) => {
          if (temp < 38) {
            this.temp_status = "OK!";
          }
          if (temp >= 38 && temp <= 40) {
            this.temp_status = "Oops!";
          }
          if (temp > 40) {
            this.temp_status = "NOK!";
          }
        },
      };

      let classeClimatica =
        this.$store.state.devices.currentDevice.model_climate_class;

      dadosClasseClimatica[classeClimatica](data);
    },
    hideIfHasDefrostSensorAndIsClient() {
      if (this.hasDefrostSensor) {
        return !this.$roles.isClient(this.userData, this.currentDevicerRoles)
      }
      else {
        return true
      }
    }
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>