<template>
  <div id="01">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="adequacao-device">
          <b-card-body>
            <div class="adequacao-top">
              <span class="adequacao-icon">
                <feather-icon icon="CheckSquareIcon" />
              </span>
              <span class="text-adequacao"> {{ $t("Fit for Use") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row class="conservation-mode">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <feather-icon class="icon-term" icon="ThermometerIcon" />
                </span>
                <span class="text-int"> {{ $t("Operation") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="d-flex"
                v-if="([0, 1].includes(currentMetricBlastChiller.cycle_type_status) && isInternalTempDataValid && isProbeTempDataValid)">
                <div>
                  <span class="graph-dot-blue"></span>
                  <span class="graph-legend">{{
                $t("Cabinet Internal Temp.")
              }}</span>
                </div>
                <div class="ml-2">
                  <span class="graph-dot-blue-light"></span>
                  <span class="graph-legend"> {{ $t("Cabinet Internal Temp. Set") }}</span>
                </div>
              </div>

              <div class="d-flex mb-5"
                v-if="(currentMetricBlastChiller.cycle_type_status === 0 && isProbeTempDataValid)">
                <div>
                  <span class="graph-dot-orange"></span>
                  <span class="graph-legend"> {{ $t("Needle Prob Temp") }}</span>
                </div>
                <div class="ml-2">
                  <span class="graph-dot-orange-light"></span>
                  <span class="graph-legend"> {{ $t("Needle Prob Temp. Set") }}</span>
                </div>
              </div>
              <vue-slider v-model="value" :direction="direction" class="mb-2 p-0 range-temp" />

              <div v-if="isLoadingAdeqTop" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqTop ? 'display:none':''">
                <device-info-adequacao-top />
              </div>
            </b-col>
          </b-row>
          <hr v-if="hasInternalHumidity" />
          <b-row v-if="hasInternalHumidity">
            <b-col cols="12" xl="3">
              <div class="temp-left">
                <span>
                  <img class="icon-term" src="@/assets/images/icons/feeze-cicle.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Indoor Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{
                $t("Indoor Relative Humidity")
              }}</span>
              <div v-if="isLoadingAdeqHumInt" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqHumInt ? 'display:none':''">
                <device-info-adequacao-humidade-int />
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row class="conservation-mode timer mb-5">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <feather-icon class="icon-term" icon="ClockIcon" />
                </span>
                <span class="text-int"> {{ $t("Multi-timer") }}</span>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="mb-5 d-flex">
                <div class="ml-2">
                  <span class="graph-dot-blue-light"></span>
                  <span class="graph-legend"> {{ $t("Time remaining") }}</span>
                </div>
                <div class="ml-2">
                  <span class="graph-dot-blue"></span>
                  <span class="graph-legend"> {{ $t("Overtime") }}</span>
                </div>
              </div>
              <b-row>
                <b-col cols="12" xl="3">
                  <div style="position: relative">
                    <div class="graph-legend-inside white" v-if="(currentMetricBlastChiller.timer_1 != -32764)">
                      {{ currentMetricBlastChiller.timer_1 }}<span>min</span>
                    </div>
                    <div class="graph-legend-inside white" v-else>
                      <span>-</span>
                    </div>
                    <vue-apex-charts type="pie" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div class="alert-icon-device-completed" v-if="(currentMetricBlastChiller.timer_1 == 0)">
                    <span class="alert-device-text small">{{ $t("Timer") }} #1</span>
                    <div class="alert-icon-device">
                      <img src="@/assets/images/icons/ok.png" alt="" class="small" />
                    </div>
                    <span class="alert-device-text">{{ $t("Task Completed") }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" xl="3">
                  <div style="position: relative">
                    <div class="graph-legend-inside white" v-if="(currentMetricBlastChiller.timer_2 != -32764)">
                      {{ currentMetricBlastChiller.timer_2 }}<span>min</span>
                    </div>
                    <div class="graph-legend-inside white" v-else>
                      <span>-</span>
                    </div>
                    <vue-apex-charts type="pie" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div class="alert-icon-device-completed" v-if="(currentMetricBlastChiller.timer_2 == 0)">
                    <span class="alert-device-text small">{{ $t("Timer") }} #2</span>
                    <div class="alert-icon-device">
                      <img src="@/assets/images/icons/ok.png" alt="" class="small" />
                    </div>
                    <span class="alert-device-text">{{ $t("Task Completed") }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" xl="3">
                  <div style="position: relative">
                    <div class="graph-legend-inside white" v-if="(currentMetricBlastChiller.timer_3 != -32764)">
                      {{ currentMetricBlastChiller.timer_3 }}<span>min</span>
                    </div>
                    <div class="graph-legend-inside white" v-else>
                      <span>-</span>
                    </div>
                    <vue-apex-charts type="pie" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div class="alert-icon-device-completed" v-if="(currentMetricBlastChiller.timer_3 == 0)">
                    <span class="alert-device-text small">{{ $t("Timer") }} #3</span>
                    <div class="alert-icon-device">
                      <img src="@/assets/images/icons/ok.png" alt="" class="small" />
                    </div>
                    <span class="alert-device-text">{{ $t("Task Completed") }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" xl="3">
                  <div style="position: relative">
                    <div class="graph-legend-inside white" v-if="(currentMetricBlastChiller.timer_4 != -32764)">
                      {{ currentMetricBlastChiller.timer_4 }}<span>min</span>
                    </div>
                    <div class="graph-legend-inside white" v-else>
                      <span>-</span>
                    </div>
                    <vue-apex-charts type="pie" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div v-if="(currentMetricBlastChiller.timer_4 == 0)" class="alert-icon-device-completed">
                    <span class="alert-device-text small">{{ $t("Timer") }} #4</span>
                    <div class="alert-icon-device">
                      <img src="@/assets/images/icons/ok.png" alt="" class="small" />
                    </div>
                    <span class="alert-device-text">{{ $t("Task Completed") }}
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-row class="conservation-mode" v-if="this.$roles.isAdmin(this.userData)">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <feather-icon class="icon-term" icon="RefreshCwIcon" />
                </span>
                <span class="text-int"> {{ $t("Holding Mode") }}</span>
              </div>
            </b-col>
            <b-col lg="6">
              <b-row>
                <b-col class="" lg="6" style="text-align: center">
                  <span class="text-int small"> Chill </span>
                  <div style="text-align: center; margin: 15px auto; position: relative;">
                    <div class="graph-legend-inside center black">
                      0<span style="font-weight: 400">Energy Cost</span>
                    </div>
                    <vue-apex-charts type="donut" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div class="status-icons">
                    <div style="margin-bottom: 15px">
                      <div class="icon-status-black">
                        <feather-icon class="icon-term" icon="ClockIcon" />
                        <span>0,92 kWh</span>
                      </div>
                    </div>

                    <div>
                      <span class="consumo-text-green">-0,02 kWh</span>
                      <div class="icon-status-black">
                        <img src="@/assets/images/icons/consumo-green.png" alt="" />
                        <span>0,92 kWh</span>
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col class="" lg="6" style="text-align: center" v-if="this.$roles.isAdmin(this.userData)">
                  <span class="text-int small">{{ $t("Freeze") }}</span>
                  <div style="
                                            text-align: center;
                                            margin: 15px auto;
                                            position: relative;
                                          ">
                    <div class="graph-legend-inside center black">
                      0<span style="font-weight: 400">Energy Cost</span>
                    </div>
                    <vue-apex-charts type="donut" width="100%" :options="chartOptions" :series="series" />
                  </div>
                  <div class="status-icons">
                    <div style="margin-bottom: 15px">
                      <div class="icon-status-black">
                        <feather-icon class="icon-term" icon="ClockIcon" />
                        <span>0,92 kWh</span>
                      </div>
                    </div>

                    <div>
                      <span class="consumo-text-red">+0,02 kWh</span>
                      <div class="icon-status-black">
                        <img src="@/assets/images/icons/consumo-red.png" alt="" />
                        <span>0,92 kWh</span>
                      </div>
                    </div>
                  </div>
                  <div class="info-btn-holding-mode">
                    <b-button class="btn-sm temp-info-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.left="$t(
                'Total cost and time that the conservation mode was active after completing a cycle. to max. efficiency reduces this value.'
              )
                " variant="none">
                      <feather-icon class="temp-info-icon" icon="AlertCircleIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr v-if="hasInternalHumidity" />
          <b-row v-if="hasInternalHumidity">
            <b-col cols="12" xl="3">
              <div class="temp-left">
                <span>
                  <img class="icon-term" src="@/assets/images/icons/feeze-cicle.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Indoor Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{
                $t("Indoor Relative Humidity")
              }}</span>
              <div v-if="isLoadingAdeqHumInt" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqHumInt ? 'display:none':''">
                <device-info-adequacao-humidade-int />
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row class="conservation-mode">
            <b-col lg="2">
              <div class="temp-left">
                <span class="img-icon">
                  <feather-icon class="icon-term" icon="CalendarIcon" />
                </span>
                <span class="text-int"> {{ $t("Programs Used") }} </span>
              </div>
            </b-col>
            <b-col lg="8">
              <Calendar />
            </b-col>
          </b-row>
          <hr v-if="hasInternalHumidity" />
          <b-row v-if="hasInternalHumidity">
            <b-col cols="12" xl="3">
              <div class="temp-left">
                <span>
                  <img class="icon-term" src="@/assets/images/icons/feeze-cicle.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Indoor Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{
                $t("Indoor Relative Humidity")
                }}</span>
                <div v-if="isLoadingAdeqHumInt" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqHumInt ? 'display:none':''">
                <device-info-adequacao-humidade-int />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import DeviceInfoAdequacaoTempInt from "./DeviceInfoAdequacaoTempInt.vue";
import DeviceInfoAdequacaoTop from "./DeviceInfoAdequacaoTop.vue";
import Calendar from "./calendar/Calendar02.vue";
import DeviceInfoAdequacaoHumidadeInt from "./DeviceInfoAdequacaoHumidadeInt.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoAdequacaoTempInt,
    DeviceInfoAdequacaoTop,
    DeviceInfoAdequacaoHumidadeInt,
    Calendar,
    VueApexCharts,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      value: 10,
      series: [25, 15],
      chartOptions: {
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: false,
          },
        },

        legend: {
          show: false,
        },
      },

    };
  },
  computed: {
    isLoadingAdeqTop(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_adeq_top
    },
    isLoadingAdeqHumInt(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_adeq_hum_int
    },
    hasInternalHumidity() {
      return this.$store.state.devices.currentDevice
        .has_internal_relative_humidity_sensor;
    },
    currentMetricBlastChiller() {
      return this.$store.state.devices.currentMetricBlastChiller;
    },
    userData() {
      return this.$store.state.users.currentUser;
    },
    isInternalTempDataValid() {
      const invalidValues = [32767, -32764];

      return !(
        invalidValues.includes(this.currentMetricBlastChiller.internal_temp) ||
        invalidValues.includes(this.currentMetricBlastChiller.internal_temp_set)
      );
    },
    isProbeTempDataValid() {
      const invalidValues = [32767, -32764];

      return !(
        invalidValues.includes(this.currentMetricBlastChiller.probe_temp) ||
        invalidValues.includes(this.currentMetricBlastChiller.probe_temp_set)
      );
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  methods: {
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
