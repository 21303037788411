export default {
  lineChartSimple: {
    series: [
      {
        name: 'Ambient Temperature',
        data: [45, 50, 40, 60, 45],
      },
      {
        name: 'Ambient Relative Humidity',
        data: [0, 10, 20, 30, 25],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 1,
        strokeOpacity: 1,
        strokeColors: '#2f79bb',
        colors: '#2f79bb',
      },
      colors: ['#2f79bb', '#4faef2'],
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
            }°C</span></div>`
        },
      },
      xaxis: {
        categories: [
          '4:00h',
          '6:00h',
          '8:00h',
          '10:00h',
          '12:00h',
        ],
      },
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          title: {
            text: "Temperatura ambiente",
          },
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + " °C";
            },
          }
        },
        {
          opposite: true,
          axisTicks: {
            show: true
          },
          title: {
            text: "Humidade relativa ambiente",
          },
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + " °C";
            },
          }
        }
      ]
    },
  },
}
