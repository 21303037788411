export default {
  lineChartSimple: {
    series: [],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
      },
      // markers: {
      //   strokeWidth: 1,
      //   strokeOpacity: 1,
      //   strokeColors: '#2f79bb',
      //   colors: '#2f79bb',
      // },
      colors: ['#2f79bb', 'transparent'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
            }°C</span></div>`
        },
      },
      xaxis: {
        labels: { show: false },
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
      noData: {
        text: "No Data",
      },
    },
  },
}


