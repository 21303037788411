<template>
  <div id="01">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="adequacao-device">
          <b-card-body>
            <div class="adequacao-top">
              <span class="adequacao-icon">
                <feather-icon icon="CheckSquareIcon" />
              </span>
              <span class="text-adequacao"> {{ $t("Fit for Use") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row class="conservation-mode">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <feather-icon class="icon-term" icon="ThermometerIcon" />
                </span>
                <span class="text-int"> {{ $t("Internal Temperature") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="mb-1">
                <span class="graph-dot-blue"></span>
                <span class="graph-legend">{{
                  $t("Internal Temperature")
                }}</span>
              </div>
              <div v-if="isLoadingAdeqTop" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqTop ? 'display:none':'display:block'">
                <vue-slider
                v-model="value"
                :direction="direction"
                class="mb-2 p-0 range-temp"
              />
              <device-info-adequacao-top :data="data.salesChart" />
              </div>
            </b-col>
          </b-row>
          <hr v-if="hasInternalHumidity" />
          <b-row v-if="hasInternalHumidity">
            <b-col cols="12" xl="3">
              <div class="temp-left">
                <span>
                  <img
                    class="icon-term"
                    src="@/assets/images/icons/feeze-cicle.png"
                    alt=""
                  />
                </span>
                <span class="text-int">
                  {{ $t("Indoor Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{
                $t("Indoor Relative Humidity")
              }}</span>
              <div v-if="isLoadingAdeqTop" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingAdeqTop ? 'display:none':'display:block'">
                <device-info-adequacao-humidade-int />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import DeviceInfoAdequacaoTempInt from "./DeviceInfoAdequacaoTempInt.vue";
import DeviceInfoAdequacaoTop from "./DeviceInfoAdequacaoTop.vue";
import Calendar from "./calendar/Calendar.vue";
import DeviceInfoAdequacaoHumidadeInt from "./DeviceInfoAdequacaoHumidadeInt.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoAdequacaoTempInt,
    DeviceInfoAdequacaoTop,
    Calendar,
    DeviceInfoAdequacaoHumidadeInt,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {
        value: 10,

      },
    };
  },
  computed: {
    isLoadingAdeqTop(){
      return this.$store.state.devices.graph_loadings.loading_adeq_top
    },
    hasInternalHumidity() {
      return this.$store.state.devices.currentDevice
        .has_internal_relative_humidity_sensor;
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>