<template>
  <div id="02">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="envolvente-device">
          <b-card-body>
            <div class="envolvente-top">
              <span class="envolvente-icon">
                <feather-icon icon="CreditCardIcon" />
              </span>
              <span class="text-envolvente"> {{ $t("Ambient") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/cooker.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Ambient Temperature and Relative Humidity") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingTempAmbHumRel" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <b-col :style="isLoadingTempAmbHumRel ? 'display:none':''" lg="6">
              <device-info-envolvente-temp-amb
                @updateBarsHumidityTemp="updateSliders"
              />
              <div class="mb-1">
                <span class="graph-dot-blue"></span>
                <span class="graph-legend">{{
                  $t("Ambient Temperature")
                }}</span>
              </div>
              <vue-slider
                v-model="value"
                :direction="direction"
                class="mb-2 p-0 range-temp"
              />
            </b-col>
          </b-row>
          <hr  v-if="this.isNotClient"/>
          <b-row v-if="this.isNotClient">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/condensador.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Condenser Undercooling") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingCondUnd" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <b-col :style="isLoadingCondUnd ? 'display:none':''" lg="6">
              <device-info-envolvente-subarrefecimento-condensador />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import store from "@/store/index";
import DeviceInfoEnvolventeTempAmb from "../Envolvente/DeviceInfoEnvolventeTempAmb.vue";
import DeviceInfoEnvolventeSobreaquec from "../Envolvente/DeviceInfoEnvolventeSobreaquec.vue";
import DeviceInfoEnvolventeTop from "../Envolvente/DeviceInfoEnvolventeTop.vue";
import VueGauge from "vue-gauge";
import DeviceInfoEnvolventeEficaciaCondensador from "./DeviceInfoEnvolventeEficaciaCondensador.vue";
import DeviceInfoEnvolventeSubarrefecimentoCondensador from "./DeviceInfoEnvolventeSubarrefecimentoCondensador.vue";

export default {
  components: {
    VueSlider,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormGroup,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeTempAmb,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoEnvolventeTop,
    VueGauge,
    DeviceInfoEnvolventeEficaciaCondensador,
    DeviceInfoEnvolventeSubarrefecimentoCondensador,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {
        value: 10
      },
      value: 10,
      value2: 10,
      temp_status: "OK!",
      hum_status: "OK!",
    };
  },
  methods: {
    updateSliders(data) {
      let dadosClasseClimatica = {
        3: (temp) => {
          if (temp < 23) {
            return 10;
          }
          if (temp >= 23 && temp <= 25) {
            return 50;
          }
          if (temp > 25) {
            return 90;
          }
        },
        4: (temp) => {
          if (temp < 28) {
            return 10;
          }
          if (temp >= 28 && temp <= 30) {
            return 50;
          }
          if (temp > 30) {
            return 90;
          }
        },
        5: (temp) => {
          if (temp < 38) {
            return 10;
          }
          if (temp >= 38 && temp <= 40) {
            return 50;
          }
          if (temp > 40) {
            return 90;
          }
        },
      };

      let classeClimatica =
        this.$store.state.devices.currentDevice.model_climate_class;

      this.value = dadosClasseClimatica[classeClimatica](
        data["Ambient Temperature"]
      );
    },
    verifyTempStatus(data) {
      let dadosClasseClimatica = {
        3: (temp) => {
          if (temp < 23) {
            this.temp_status = "OK!";
          }
          if (temp >= 23 && temp <= 25) {
            this.temp_status = "Oops!";
          }
          if (temp > 25) {
            this.temp_status = "NOK!";
          }
        },
        4: (temp) => {
          if (temp < 28) {
            this.temp_status = "OK!";
          }
          if (temp >= 28 && temp <= 30) {
            this.temp_status = "Oops!";
          }
          if (temp > 30) {
            this.temp_status = "NOK!";
          }
        },
        5: (temp) => {
          if (temp < 38) {
            this.temp_status = "OK!";
          }
          if (temp >= 38 && temp <= 40) {
            this.temp_status = "Oops!";
          }
          if (temp > 40) {
            this.temp_status = "NOK!";
          }
        },
      };

      let classeClimatica =
        this.$store.state.devices.currentDevice.model_climate_class;

      dadosClasseClimatica[classeClimatica](data);
    },
  },
  computed: {
    isLoadingTempAmbHumRel(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_temp_amb_hum_rel
    },
    isLoadingCondUnd(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_cond_undercooling
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
    temp_status() {
      return "OK!";
    },
    device_status() {
      this.currentAmbTempStatus
      const level = { "OK!": 1, "Oops!": 2, "NOK!": 3 };
      const temp_level = level[this.temp_status];
      const hum_level = level[this.hum_status];

      if (temp_level >= hum_level) {
        return this.temp_status;
      } else {
        return this.hum_status;
      }
    },
    currentAmbTempStatus()
    {
      let data =  this.$store.state.devices.currentAmbTempStatus;
      this.verifyTempStatus(data)
    },
    device_status_condenser() {
      this.currentCondenserTemp;
      const level = { "OK!": 1, "Oops!": 2, "NOK!": 3 };
      const temp_level = level[this.temp_status_condenser];

      return this.temp_status;
    },
    currentDevicerRoles()
    {
      return this.$store.state.devices.currentDevicerRoles
    },
    userData() {
      return this.$store.state.users.currentUser;
    },
    isNotClient(){
      return !this.$roles.isClient(this.userData, this.currentDevicerRoles)
    }
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>