export default {
  lineChartSimple: {
    series: [
      {
        name: 'Inlet Temperature',
        data: [45, 46, 40, 45, 41],
      },
      {
        name: 'Outlet Temperature',
        data: [35, 36, 39, 35, 39],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: true,
          type: "xy",
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          selection: {
            background: "#90CAF9",
            border: "#0D47A1",
          },
        },
        toolbar: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
      },
      markers: {
        strokeWidth: 1,
        strokeOpacity: 1,
        strokeColors: '#2f79bb',
        colors: '#2f79bb',
      },
      colors: ['#2f79bb', '#4faef2'],
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }°C</span></div>`
        },
      },
      xaxis: {
        // categories: [],
      },
      yaxis: {
        // opposite: isRtl,
        labels: {
          formatter: function (val, index) {
            return val.toFixed(0) + " kWh";
          }
        },
        tickAmount:1,
      },
    },
  },
}
