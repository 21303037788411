<template>
  <div id="06">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="performace-device">
          <b-card-body>
            <div class="performace-top">
              <span class="performace-icon">
                <feather-icon icon="EditIcon" />
              </span>
              <span class="text-performace"> {{ $t("Details") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <validation-observer ref="updateForm">
          <b-card class="section-temp">
            <div v-if="readMode">
              <b-row>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Type") }}: </span
                  >{{ device.typology }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Model") }}: </span
                  >{{ device.model_code }}
                </b-col>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Name") }}:</span
                  >
                  {{ device.name }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Serial No") }}: </span
                  >{{ device.serial_number }}
                </b-col>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Site") }}: </span
                  >{{ device.location }}
                </b-col>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Currency") }}:</span
                  >
                  {{ device.currency }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("MAC address") }}: </span
                  >{{ device.mac_number }}
                </b-col>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small">{{$t("Time zone")}}: </span
                  >{{ device.timezone }}
                </b-col>
                <b-col md="12" lg="4" class="mb-1">
                  <span class="text-status-reports small"
                    >{{ $t("Price kWh") }}:</span
                  >
                  {{ device.energy_price }}
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <button
                    type="button"
                    class="btn search-btn m-0"
                    @click="setReadMode(false)"
                  >
                    <span>{{ $t("Edit") }}</span>
                  </button>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col cols="4">
                  <strong>{{ $t("Site") }}: </strong>
                  <b-form-input
                    id="deviceLocation"
                    v-model="deviceLocation"
                    name="device-location"
                    class="mt-1"
                  />
                </b-col>
                <b-col cols="4">
                  <strong>{{$t("Time zone")}}: </strong>
                  <v-select
                    id="deviceTimezone"
                    v-model="deviceTimezone"
                    :options="timeZoneList"
                    :searchable="true"
                    class="mt-1"
                    :placeholder="device.timezone"
                    label="name"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" class="mt-1"
                  ><strong>{{ $t("Name") }}:</strong>
                  <b-form-input
                    id="deviceName"
                    v-model="deviceName"
                    name="device-name"
                    class="mt-1"
                  />
                </b-col>

                <b-col cols="4" class="mt-1"
                  ><strong>{{ $t("Currency") }}:</strong>
                  <v-select
                    id="currency"
                    v-model="deviceCurrency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="
                      (option) => !option.value.includes('select_currency')
                    "
                    :options="currencyOptions"
                    label="text"
                    :searchable="false"
                    class="mt-1"
                    :placeholder="device.currency"
                  />
                </b-col>
                <b-col cols="4" class="mt-1"
                  ><strong>{{ $t("Price kWh") }}:</strong>
                  <b-form-input
                    id="deviceEnergyPrice"
                    v-model="deviceEnergyPrice"
                    name="device-energy-price"
                    class="mt-1"
                    type="number"
                    min="1"
                    step="any"
                  />
                </b-col>

                <b-col cols="12">
                  <button
                    type="button"
                    class="btn search-btn m-0 mt-2"
                    @click="saveDeviceDescription"
                  >
                    <span>Save</span>
                  </button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment-timezone";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
      required,
      readMode: true,
      currencyOptions: [
        { value: "EUR", text: "EUR" },
        { value: "PLN", text: "PLN" },
        { value: "CZK", text: "CZK" },
        { value: "SEK", text: "SEK" },
        { value: "NOK", text: "NOK" },
        { value: "DKK", text: "DKK" },
        { value: "CHF", text: "CHF" },
        { value: "GBP", text: "GBP" },
      ],
    };
  },
  methods: {
    setReadMode(state) {
      this.readMode = state;
    },
    saveDeviceDescription() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.$store.dispatch("devices/updateDeviceDetails", {
            context: this,
          });
          this.setReadMode(true);
        }
      });
    },
  },
  computed: {
    device() {
      return this.$store.state.devices.currentDevice;
    },
    deviceDescription: {
      get() {
        return this.$store.state.devices.currentDevice.description;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_DESCRIPTION", newValue);
      },
    },
    deviceName: {
      get() {
        return this.$store.state.devices.currentDevice.name;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_NAME", newValue);
      },
    },
    deviceLocation: {
      get() {
        return this.$store.state.devices.currentDevice.location;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_LOCATION", newValue);
      },
    },
    deviceTimezone: {
      get() {
        return this.$store.state.devices.currentDevice.deviceTimezone;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_TIMEZONE", newValue.value);
      },
    },
    deviceCurrency: {
      get() {
        return this.$store.state.devices.currentDevice.currency;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_CURRENCY", newValue.value);
      },
    },
    deviceEnergyPrice: {
      get() {
        return this.$store.state.devices.currentDevice.energy_price;
      },
      set(newValue) {
        this.$store.commit("devices/SET_DEVICE_ENERGY_PRICE", newValue);
      },
    },
    timeZoneList: {
      get() {
        let countries_options = [
          "PT",
          "ES",
          "FR",
          "GB",
          "DE",
          "IT",
          "PL",
          "FI",
          "AE",
          "SA",
        ];
        let tz_options = [];
        countries_options.forEach((element) => {
          moment.tz.zonesForCountry(element, true).forEach((result) => {
            let tzone = moment.tz.zone(result.name);
            let time = tzone.parse(Date.now()) / 60;
            tz_options.push({
              value: result.name,
              name: `${result.name.split("/")[1]} / UTC${
                time >= 0 ? `+${time}` : time
              }`,
            });
          });
        });
        return tz_options;
      },
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
