<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        type="line"
        height="242"
        :options="adequacaoChartData.lineChartSimple.chartOptions"
        :series="adequacaoChartData.lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import adequacaoChartData from "./adequacaoChartData";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      adequacaoChartData,
    };
  },
  created() {
    // Example: this.$http.get('.../device/<:id>/get-metrics/?metric=internalTemp&interval=24')
  },
};
</script>
